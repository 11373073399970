<template>
  <b-container fluid class="auth-background">
    <div class="auth-card auth-card-container">
      <img
        id="profile-img"
        src="@/assets/mnwd_logo.png"
        class="auth-card-img mb-4"
      />
      <h5>Sign In</h5>
      <label>Email</label>
      <form>
        <b-form-group :invalid-feedback="invalidEmailFeedback">
          <b-form-input
            id="nested-email"
            :state="emailState"
            v-model="email"
            @blur="emailBlur = true"
          ></b-form-input>
        </b-form-group>

        <label>Password</label>
        <b-form-group :invalid-feedback="invalidPasswordFeedback">
          <b-form-input
            id="nested-password"
            :state="passwordState"
            v-model="password"
            type="password"
            @blur="passwordBlur = true"
          ></b-form-input>
        </b-form-group>

        <b-button
          type="submit"
          :disabled="!loginState || loading"
          variant="primary"
          @click="handleLogin"
        >
          SIGN IN
        </b-button>
      </form>

      <br />

      <router-link :to="{ name: 'resetPassword' }"
        >Forgot your password?</router-link
      >
    </div>
    <div class="auth-card auth-card-container">
      New user?
      <router-link :to="{ name: 'register' }"
        >Register A New Account</router-link
      >
    </div>
  </b-container>
</template>

<script>
import validator from "@/mixins/validator";
export default {
  name: "SignIn",
  mixins: [validator],
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    notImplemented() {
      console.error("Not implemented");
    },
    handleLogin() {
      if (this.isValidLogin()) {
        this.loading = true;
        this.$store
          .dispatch("auth/login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.$router.push("/");
          })
          .catch((e) => {
            const data = e.data;
            if (data?.message) {
              this.notify("danger", "Login failed", "Error: " + data.message);
            } else {
              this.notify("danger", "Login failed", "Unknown server error:");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.error("Invalid login form.");
      }
    },
  },
};
</script>
