export default {
  data() {
    return {
      usernameBlur: null,
      emailBlur: null,
      passwordBlur: null,
      repeatPasswordBlur: null,
    };
  },
  methods: {
    isValidEmail() {
      return /.+@.+\..+/.test(this.email);
    },
    isValidUsername() {
      return this.username.length >= 4;
    },
    isValidPassword() {
      return this.password.length >= 6;
    },
    isValidRepeatPassword() {
      return this.isValidPassword() && this.password === this.repeatPassword;
    },
    isValidLogin() {
      return this.isValidEmail() && this.isValidPassword();
    },
    isValidRegister() {
      return (
        this.isValidEmail() &&
        this.isValidPassword() &&
        this.isValidUsername() &&
        this.isValidRepeatPassword()
      );
    },
  },
  computed: {
    loginState() {
      return this.isValidLogin();
    },
    registerState() {
      return this.isValidRegister();
    },
    emailState() {
      if (!this.emailBlur) return null;
      return this.isValidEmail();
    },
    usernameState() {
      if (!this.usernameBlur) return null;
      return this.isValidUsername();
    },
    passwordState() {
      if (!this.passwordBlur) return null;
      return this.isValidPassword();
    },
    repeatPasswordState() {
      if (!this.repeatPasswordBlur) return null;
      return this.isValidRepeatPassword();
    },
    invalidEmailFeedback() {
      if (!this.email.length) return "Required.";
      if (!this.emailState) return "Please enter a valid email";
      return "";
    },
    invalidUsernameFeedback() {
      if (!this.username.length) return "Required.";
      if (!this.usernameState)
        return "Username must be at least 4 characters long";
      return "";
    },
    invalidPasswordFeedback() {
      if (!this.password.length) return "Required.";
      if (!this.passwordState)
        return "Password must be at least 6 characters long";
      return "";
    },
    invalidRepeatPasswordFeedback() {
      if (!this.repeatPassword.length) return "Required.";
      if (this.repeatPassword.length < 6)
        return "Password must be at least 6 characters long";
      if (this.password !== this.repeatPassword) return "Passwords must match";
      return "";
    },
  },
};
